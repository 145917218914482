<template>
	<div id="container" class="container">
		<div id="sub" class="sub detail">
			<!-- [D] 지역 선택 -->
			<div style="display:block" class="layer">
				<div class="layer__dim"></div>
				<div class="layer__full">
					<div class="layer__fullbox">
						<div class="layer__fullheader">
							<nav class="nav">
								<div class="nav__wrap">
									<a class="nav__btn" @click="goBack()">
										<i class="icon icon-close"></i>
									</a>
									<p class="nav__item">
										<span class="nav__text">
											{{t('10677')}}
										</span>
									</p>
									<a href="#" class="nav__btn">
									</a>
								</div>
							</nav>
						</div>
						<div class="layer__fullbody">
							<div class="box">
								<div class="locations">
									<ul class="locations__list">
										<!-- [D] 활성화된 지역에 active 클래스 추가 -->
										<!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
											<li 
												v-for="menu in menus" 
												:key="menu.id" 
												:class="{ active: selectedMenuId === menu.id}" 
												v-on:click="handleMenu(menu.id)"
											>
												<a>
													{{t('area'+menu.id)}}
												</a>
										</li>
									</ul>
									<ul class="locations__sublist">
										<li v-for="location in locations" :key="location.id" >
											<a v-on:click="handleSelectLocation(location.id)">
													{{t('area'+location.id)}}
												<!-- <span class="layer__badge">{{location.count}}</span> -->
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
import numberWithCommas from "@/utils/numberWithCommas";
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 


export default {
	setup() {
		const store = useStore();
		const selectedMenuId = ref("01");
		const router = useRouter();
		const route = useRoute();
		const { t }= useI18n() 

		onMounted(() => store.dispatch("common/fetchCd086"))

		const menus = computed(() => store.state.common.cd086.reduce((acc, cur) => {
				const isIn = acc.find(item => item.id === cur.area_cd1);
				if (!isIn && cur.area_cd1 !== "00") {
					acc.push({id: cur.area_cd1, name: cur.area_nm1});
				}
				return acc;
			}, []));

		const locations = computed(() => store.state.common.cd086
			.filter((location) => location.area_cd1 === selectedMenuId.value )
			.reduce((acc, cur) => {
				if(cur.area_cd1 !== "00") {
					acc.push({id: cur.area_cd2, name: cur.area_nm2, count: numberWithCommas(cur.hotel_cnt)});
				}
				return acc;
			}, []));
		
		const handleMenu = (menuId) => {
			selectedMenuId.value = menuId;
		};

		const handleSelectLocation = (locationId) => {
			//cd086안의 "전체 전체"라는 이상한 값 필터
			const location = store.state.common.cd086.filter(item => item.area_cd1 !== "00").find(item => item.area_cd2 === locationId);
			if (location) {
				store.commit("mhm01/SET_SELECT_LOCATION", {location});
			}
			if(route.query.callbackUrl) {
				router.replace(route.query.callbackUrl);
			} else {
				router.back();
			}
		};

		const goBack = () => {
			router.back();
		};

		return {
			menus,
			selectedMenuId,
			locations,
			handleMenu,
			handleSelectLocation,
			t,  //번역필수 모듈
		  i18n, 
			goBack
			
		}
	}
}
</script>